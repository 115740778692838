export default {
  '所在地': 'address',
  'エリア': 'area',
  '間取り': 'floor plan',
  '広さ': 'space',
  '賃料': 'rent fee',
  '売価': 'price',
  search_conditions: {
    reset_all: 'reset',
    search: 'filter',
    partial_match: '(partial match)',
    not_selected: 'Not selected',
    order: {
      description: 'Change the order',
      created_at_desc: 'Sort by newest registration',
      created_at_asc: 'Sort by oldest registration',
      updated_at_desc: 'Sort by newest update',
      updated_at_asc: 'Sort by oldest update',
      lexical_order_desc: 'Sort by Japanese syllabary order(descending）',
      lexical_order_asc: 'Sort by Japanese syllabary order(ascending）',
    },
  },
  vr_control: {
    contents_list: {
      count_label: 'Contents',
      current_place_label: 'Content which is currently displaying',
      search: {
        label: 'Narrow down in conditions',
        button: 'Search conditions',
      },
      filter: {
        label: 'Narrow down more',
        placeholder: 'e.g. content name',
      },
      tab: {
        all: 'Search result',
        list: 'List',
      },
    },
    skip_ad: 'Skip this AD',
  },
  anywhere_store: {
    back: 'Back',
    no_contents: 'no match found',
    find_by_conditions: 'Filtering',
    exit: 'Exit',
    visit: {
      staff_status: {
        listening: 'Call for the further information',
        requesting: 'calling...',
        waiting_video: 'video talk connecting..',
        established: 'talking',
      },
      cancel: 'cancel',
      no_attendant: 'No staff available',
      recording_notice: 'your talk may be recorded for the quality control.',
    },
    notify: {
      STAFF_FINISHED_REASON: {
        UNLOAD: 'Connection lost',
        DISCONNECT: 'fin',
        DEFAULT: 'Connection lost for unknown reasons',
      },
      CONNECTED: '{name} participated in the call',
      DISCONNECTED: '{name} has left',
    },
  },
  _model: {
    place: {
      parent: {
        name: '施設名',
      },
      child: {
        status: 'Status',
        name: 'Content name',
        attributes: 'Information',
        column_map: 'Image for list',
        qr: 'QR code',
        points: 'Images',
        panorama: 'Panorama view',
        public: 'Open to public',
        private: 'Private',
      },
    },
  },
  places: {
    index: {
      title: 'Contents list',
      number_of_place_children: '{number}contents',
      sub_title: 'Contents are displayed below.',
      add_child: 'Add contents',
      parent_edit: 'Edit',
      child_edit: 'Edit',
      child_edit_description: 'Go to the edit page',
      child_edit_no_permission: "You don't have permission to edit",
      child_detail: 'Details',
      child_detail_description: 'Go to the page of details',
      child_register: 'Registration',
      child_display_panorama: 'Display',
      child_display_panorama_settings: 'Settings',
      current_conditions: 'Selected conditions',
      status_no_image: 'No image',
      publish_without_image: "You can't publish content without image",
      publish: 'Public',
      publish_description: 'Click to public',
      reset_conditions: 'Reset condisions',
      unpublish: 'Private',
      unpublish_description: 'Click to private',
      page_count: 'page {number}',
      filter_condition: 'Filter',
      filter_description: 'Set the conditions to display',
      map: {
        overwrite:
          'Upload another room layout image to overwrite <br />(Drag and drop available)',
        new: 'Upload room layout image<br />(Drag and drop available)',
      },
      map_point: {
        new:
          'Go to the VR image registration page<br/>(Drag and drop available)',
      },
      panorama_preview: {
        open_capture: 'Get VR image capture',
        open_settings: 'Display panorama settings',
        disable_capture: "Can't use capture without celestial sphere image",
        no_image: "Can't display image without registration",
        display: 'Display panorama view',
      },
      order: {
        description: 'Change the order',
        created_at_desc: 'Sort by newest registration',
        created_at_asc: 'Sort by oldest registration',
        updated_at_desc: 'Sort by newest update',
        updated_at_asc: 'Sort by oldest update',
      },
      show_count: {
        description: 'a number of contents to display in one page',
        show_place_count10: '10 contents',
        show_place_count20: '20 contents',
        show_place_count50: '50 contents',
        show_place_count100: '100 contents',
      },
    },
    tasks: {
      label: 'Task',
      description: 'Narrow down the conditions',
      none: 'Not selected',
      medium: '図面連続登録リスト',
      map: '間取り図連続登録リスト',
      points: 'VR画像撮影リスト',
      public: 'the list of contents in public',
    },
  },
  group_calls: {
    you: 'You',
    unread_messages_from_here: 'Unread messages from here',
    shared_medium_kind: {
      site: 'External Site',
      media: 'Video/Image',
      place: 'Place',
    },
    shared_medium_value: {
      site: 'External Site URL',
      media: 'Video/Image URL',
      place: 'Place ID',
    },
    shared_medium_placeholder: {
      site: 'https://example.com',
      media: 'https://example.com/video.mp4',
      place: 'Place ID (Number)',
    },
  },
}
