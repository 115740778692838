const texts = {
  search_conditions: {
    reset: "Reset",
    search: "Search",
    partial_match: "(Search by partial match)",
    not_selected: "Don't specify",
    order: {
      description: "Change sorting order",
      created_at_desc: "Sort by registration from newest to oldest",
      created_at_asc: "Sort by registration from oldest to newest",
      updated_at_desc: "Sort by update date from newest to oldest",
      updated_at_asc: "Sort by update date from oldest to newest",
      lexical_order_desc: "Sort by Japanese syllabary (descending order)",
      lexical_order_asc: "Sort by Japanese syllabary (ascending order)",
    },
  },
  anywhere_store: {
    back: "Back",
    no_contents: "No matching contents were found",
    find_by_conditions: "Find by conditions",
    exit: "Finish",
    hotels: "Hotels",
    visit: {
      staff_status: {
        listening: "Ask about this property by video call",
        requesting: "Inquiring...",
        waiting_video: "Connecting video...",
        established: "In call",
      },
      cancel: "Cancel",
      no_attendant: "There is no staff available right now",
      recording_notice:
        "*In order to improve the quality of customer service, please note that we may record the contents of the call.",
    },
    notify: {
      STAFF_FINISHED_REASON: {
        UNLOAD: "The connection on the staff side was interrupted",
        DISCONNECT: "Guidance has finished",
        DEFAULT: "The connection on the staff side was interrupted for an unknown reason",
      },
    },
  },
  _model: {
    place: {
      parent: {
        name: "Building name",
      },
      child: {
        status: "Status",
        name: "Room name",
        points: "Image",
        panorama: "Panorama",
        qr: "QR code",
        attributes: "Property information",
        column_map: "Floor plan",
        public: "Public",
        private: "Private",
      },
    },
  },
  vr_control: {
    contents_list: {
      count_label: "Property",
      current_place_label: "Currently displayed property",
      search: {
        label: "Filter by conditions",
        button: "Search conditions",
      },
      filter: {
        label: "Filter more",
        placeholder: "Property name, property code, etc.",
      },
      tab: {
        all: "Search results",
        list: "List",
        map: "Map",
      },
    },
    skip_ad: "Skip this ad/Skip this AD",
  },
  places: {
    index: {
      title: "List of registered properties",
      number_of_place_children: "{number} properties",
      sub_title: "This is the list of registered properties",
      add_child: "Add a room to this property",
      parent_edit: "Edit the building information",
      parent_add_items: "Register an image",
      child_edit: "Edit",
      child_edit_description: "Move to the editing page",
      child_edit_no_permission: "No permission to edit",
      child_detail: "Details",
      child_detail_description: "Move to the details page",
      child_register: "Register",
      child_display_panorama: "Display",
      child_display_panorama_settings: "Settings",
      current_conditions: "Current search conditions",
      status_no_image: "No registered image",
      publish_without_image: "Cannot publish because a image has not been registered.",
      status_no_external_id: "{Property number} not entered",
      publish_without_external_id:
        "Cannot publish because {property number} has not been entered",
      publish: "Publish",
      publish_description: "Click to publish",
      reset_conditions: "Reset",
      unpublish: "Unpublish",
      unpublish_description: "Click to unpublish",
      page_count: "{number} pages",
      filter_condition: "Filter conditions",
      filter_description: "Search by specified conditions",
      map: {
        overwrite:
          "Upload another floor plan and overwrite it<br />(Drag and drop support)",
        new: "Upload floor plan<br />(Drag and drop support)",
      },
      map_point: {
        new: "Move to the VR image registration screen<br/>(Drag and drop support)",
        home_staging: "Home staging",
        home_staging_description:
          "Move to the VR home staging and<br/>VR furniture eraser order screen",
      },
      panorama_preview: {
        open_capture: "Open the VR image capture screen",
        open_settings: "Open the panorama settings screen",
        disable_capture: "Cannot capture because there is no 360-degree image",
        no_image: "Cannot open because there is no image yet",
        display: "Check the panorama screen",
        delegate_display: "Check the linked panorama screen",
      },
      order: {
        description: "Change sorting order",
        created_at_desc: "Sort by registration from newest to oldest",
        created_at_asc: "Sort by registration from oldest to newest",
        updated_at_desc: "Sort by update from newest to oldest",
        updated_at_asc: "Sort by update from oldest to newest",
      },
      show_count: {
        description: "Change the number of items displayed per page",
        show_place_count10: "10 items",
        show_place_count20: "20 items",
        show_place_count50: "50 items",
        show_place_count100: "100 items",
      },
    },
    tasks: {
      label: "Tasks",
      description: "This is a convenient list when you repeat<br/>the same operation continuously",
      none: "Don't select",
      medium: "Plan continuous registration list",
      map: "Floor plan continuous registration list",
      points: "VR image shooting list",
      public: "Property public list",
    },
  },
};
export default texts;
